<template>
  <div>
    <b-form-checkbox-group
      v-model="selected"
      class="flex flex-col gap-1"
      :options="options"
      :name="name"
      stacked
    />
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default() {
        return 'checkbox-group'
      },
    },
    value: {
      type: Array,
      default() {
        return []
      },
    },
    options: {
      type: Array,
      default() {
        return [
          {
            value: 0,
            text: '',
          },
        ]
      },
    },
  },
  data() {
    return {
      selected: [],
    }
  },
  watch: {
    selected(newValue, oldValue) {
      this.$emit('input', newValue)
      this.$nextTick(() => {
        if (newValue.length <= 0) this.selected = oldValue
      })
    },
  },
  mounted() {
    this.selected = this.value
  },
}
</script>
